// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-archive-page-template-tsx": () => import("./../src/templates/archivePageTemplate.tsx" /* webpackChunkName: "component---src-templates-archive-page-template-tsx" */),
  "component---src-templates-gallery-page-template-tsx": () => import("./../src/templates/galleryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-gallery-page-template-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archives-tsx": () => import("./../src/pages/archives.tsx" /* webpackChunkName: "component---src-pages-archives-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-members-tsx": () => import("./../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */)
}

